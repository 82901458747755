import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/ListingHero'
import Routes from '../utilities/Routes'
import BreadCrumbComponent from '../components/BreadCrumbComponent'
import SolutionCards from "../components/SolutionCards"
import { arraySorter } from '../utilities/helperFunctions'
import { graphql } from 'gatsby'
import SectionTitleComponent from "../components/SectionTitleComponent"
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    container: {
        padding: "20px",
        [theme.breakpoints.up('sm')]: {
            padding: "2rem"
        }
    }
}))

function Solutions({ data }) {
    const classes = useStyles()
    const breadCrumbs = [
        { id: "home", label: "Home", route: Routes.home },
        { id: "solutions", label: "Solutions" }
    ]
    // const solutionList = arraySorter({ array: data.cms.brsSolutions, isNumberValue: true, checkKey: "order" })
    let featuredSolutions = arraySorter({ array: !!data.cms.featuredSolutions ? data.cms.featuredSolutions : [], isNumberValue: true, checkKey: "order" })
    let nonFeaturedSolutions = arraySorter({ array: !!data.cms.nonFeaturedSolutions ? data.cms.nonFeaturedSolutions : [], isNumberValue: true, checkKey: "order" })

    return (
        <div>
            <Layout>
                <Hero
                    title='Solutions'
                    coverImageMobile={{ url: '/heroSampleMobile.png', alternativeText: "Solutions" }}
                    coverImageWeb={{ url: '/heroSample.png', alternativeText: "Solutions" }}
                />
                <BreadCrumbComponent crumbsList={breadCrumbs} />
                <div className='mainContainer'>
                    <div className={classes.container}>
                        <SolutionCards
                            cardList={featuredSolutions}
                            featured={true}
                            baseUrl={Routes.solutions}
                        />
                    </div>
                    {
                        nonFeaturedSolutions.length !== 0 &&
                        <SectionTitleComponent
                            title='Other'
                            coloredTitle='Solutions'
                            sectionId='other-solutions'
                        >
                            <SolutionCards
                                cardList={nonFeaturedSolutions}
                                featured={false}
                                baseUrl={Routes.solutions}
                            />
                        </SectionTitleComponent>
                    }

                </div>
            </Layout>
        </div>
    )
}


export const query = graphql`
query SolutionsPageQuery {
  cms {
    featuredSolutions: brsSolutions(where: {isHighlight: true}) {
        id
        order
        showDetailPage
        slug
        subtitle
        thumbnailImage {
          url
          alternativeText
        }
        excerpt
        title
        shortDetails
      }
      nonFeaturedSolutions: brsSolutions(where: {isHighlight: false}) {
        id
        order
        showDetailPage
        slug
        subtitle
        thumbnailImage {
          url
          alternativeText
        }
        excerpt
        title
        shortDetails
      }
  }
}
`

export default Solutions
