import React from 'react'
import { Typography, makeStyles, Hidden } from '@material-ui/core'
import ImageComponent from './GeneralComponents/ImageComponent'
import generalCss from '../styles/generalCss'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    container: {
        maxWidth: generalCss.appMaxWidth,
        margin: 'auto',
        position: 'relative',
        overflow: 'hidden',
        minHeight: "200px",
        maxHeight: "350px",
    },
    subHeading: {
        lineHeight: "1.5rem",
        [theme.breakpoints.up('sm')]: {
            lineHeight: "2rem"
        }
    },
    imageStyles: {
        width: "100%",
        minHeight: "200px",
        maxHeight: "350px",
        objectFit: 'cover',
        zIndex: 0
    },
    overlayContainer: {
        width: '100%',
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "rgba(38, 59, 80, 0.7)",
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: "0px",
        [theme.breakpoints.up('sm')]: {
            padding: "0px 2rem",
            justifyContent: "flex-start",
        }
    },
    title: {
        color: 'white'
    }
}))

function Hero({
    title = '', titleVariant = 'h1',
    coverImageWeb = '', coverImageMobile = ''
}) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.overlayContainer}>
                    <Typography variant={titleVariant} className={classes.title} >{title}</Typography>
                </div>
                <Hidden smUp implementation='css'>
                    <ImageComponent
                        className={classes.imageStyles}
                        src={!!coverImageMobile.url ? coverImageMobile.url : ''}
                        alt={!!coverImageMobile.alternativeText ? coverImageMobile.alternativeText : 'cover-mobile'} />
                </Hidden>
                <Hidden xsDown implementation='css'>
                    <ImageComponent
                        className={classes.imageStyles}
                        src={!!coverImageWeb.url ? coverImageWeb.url : ""}
                        alt={!!coverImageWeb.alternativeText ? coverImageWeb.alternativeText : 'cover-web'} />
                </Hidden>
            </div>
        </div>
    )
}

export default Hero
